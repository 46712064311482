import React, { useState } from 'react';
import Demodark from '../../images/Demo-dark.png';
import JitsiSdk from '../../components/JitsiSdk';
import IframeDemo from './IframeDemo';
import CustomizedUrl from '../../components/CustomizedUrl';

const ShowDemo = () => {
  const [selectedTab, setSelectedTab] = useState('customizedUrl');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    // console.log('Selected Tab:', tab);
  };
  const VCPortal= "VCPortal"
  const meetingName = "DemoMeeting"

  return (
    <div style={styles.Demopage}>
      <header style={styles.header}>
        <img src={Demodark} style={styles.icon} alt="Demo Icon" />
        <h1 style={{ color: "#001E51", fontFamily: "Gothic A1", fontSize: "50px",   }}>Demo</h1>
      </header>
      <div style={styles.customTextContainer}>
        <h2 
          onClick={() => handleTabClick('customizedUrl')}
          style={selectedTab === 'customizedUrl' ? { borderBottom: '2px solid #001E51', cursor: 'pointer' } : { cursor: 'pointer' }}
        >
          Generate URL
        </h2>
        <h2
          onClick={() => handleTabClick('iFrame')}
          style={selectedTab === 'iFrame' ? { borderBottom: '2px solid #001E51', cursor: 'pointer', marginBottom:"10px" } : { cursor: 'pointer' }}
        >
          iFrame
        </h2>
        <h2
          onClick={() => handleTabClick('videoCalling')}
          style={selectedTab === 'videoCalling' ? { borderBottom: '2px solid #001E51', cursor: 'pointer' } : { cursor: 'pointer' }}
        >
          Video Calling
        </h2>
        <h2
        onClick={() => handleTabClick('MultiStreaming')}
        style={selectedTab === 'MultiStreaming' ? { borderBottom: '2px solid #001E51', cursor: 'pointer' } : { cursor: 'pointer' }}
      >
        MultiStreaming
      </h2>

      </div>
      
      <div style={styles.contentContainer}>
        {selectedTab === 'customizedUrl' && (
          <div>
            <CustomizedUrl />
          </div>
        )}
        {selectedTab === 'iFrame' && (
          <div style={styles.iframedemo}>
            <IframeDemo />
          </div>
        )}
        {selectedTab === 'videoCalling' && (
          <div style={styles.demoJitsi}>
            <JitsiSdk displayName={VCPortal} meetingName={meetingName} />
          </div>
        )}
        {selectedTab === 'MultiStreaming' && (
          <div>
            <p>Content for MultiStreaming Calling tab.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowDemo;

const styles = {
  Demopage: {
    display: 'flex',
 height: 177,
  },
  header: {
    position: "absolute",
    display: 'flex',
    alignSelf: 'left',
    alignItems: 'left',
    flexDirection: 'row',
    marginLeft: 60,
    marginTop: 5,
    gap: "24px",
  },
  icon: {
    height: '20%',
    width: '20%',
  },
  demoJitsi : {
    width: '1080px',
    height: '600px',
    top: '59px',
    position: 'absolute',
    left: '-10px'

  },
  customTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    marginTop: 100,
    marginLeft: 80,  
    gap:"60px",
    color:"#0077b6",
    fontSize: 16,
    
  },
  contentContainer: {
position: "absolute",
    marginTop: 280,
    display: 'flex',
    alignSelf : 'center',
    justifyContent : 'center',
    alignSelf:"center",
    marginLeft:"10%"
  },
  border:{
    borderBottom: '2px solid #001E51',
    marginTop :80
  },
  iframedemo : {
    width: '1080px',
    height: '600px',
    top: '59px',
    position: 'absolute',
    left: '-10px'

  },

};