import React from 'react';
import Dashboarddark from '../../images/Dashboardicon.png';
import LoginArrow from '../../images/LoginArrow.png';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router';

const OwnerLanding = () => {
  // const handleJoinNow = (meetingId) => {
  //   // Placeholder for the logic to handle the "Join Now" button click
  //   console.log(`Join Now clicked for meeting ID: ${meetingId}`);
  //   // Add your actual logic here
  // };

  const navigate=useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    window.history.pushState(null, null, '/');
    navigate("/");
  }

  return (
    <div style={styles.container}>
    <div style={styles.logoutButtonContainer}>
      <div style={styles.loginArrowBox}>
        <img src={LoginArrow} style={styles.loginArrow} alt="Login Arrow" />
        <p style={styles.logout} onClick={handleLogout}> Logout</p>
      {/* <button Style={{border:"none",color:"#001E51"}} onClick={handleLogout}> Logout</button> */}
    </div>
    </div>
      <div style={styles.main}>
        <img src={Dashboarddark} style={styles.icon} alt="Demo Icon" />
        <h1 style={styles.heading}>Dashboard</h1>
      </div>
      {/* <div style={{ width: "80%", display: "flex", justifyContent: 'center', alignSelf: "center", flexDirection: "column", color: " #0077B6", fontSize: 22 }}>
        <h2 style={{ marginTop: "0px", fontFamily: "GothicA1", Color: "#0077B6", fontSize: 28 }}>My Schedule</h2>
        <div style={{ width: "48%", display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: "row", gap: "15px" }}>
        </div>
        <table style={styles.table}>
          <thead style={{ fontSize: "16px" }}>
            <tr>
              <th>Creator</th>
              <th>Meeting id</th>
              <th>Timing</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ color: "#202833", fontSize: "14px", backgroundColor: "#F3F3F3" }}>
            <tr>
              <td>1</td>
              <td>jdhhijmoijmp.bfduhtur8uu89uj8</td>
              <td>10:00 AM - 11:00 AM</td>
              <td><button style={styles.joinButton} >Join Now</button></td>
            </tr>
            <tr>
              <td>2</td>
              <td>jdhhijmoijmp.bfduhtur8uu89uj8</td>
              <td>2:00 PM - 3:00 PM</td>
              <td><button style={styles.joinButton}>Join Now</button></td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <div style={{ width: "80%", display: "flex", justifyContent: 'center', alignSelf: "center", flexDirection: "column", color: " #0077B6", fontSize: 22, marginTop: "3%" }}>
        <h2 style={{ marginTop: "0px", fontFamily: "GothicA1", Color: "#0077B6", fontSize: 28 }}>Payment Status</h2>
        <div style={{ width: "48%", display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: "row", gap: "15px" }}>
        </div>
        <table style={styles.table}>
          <thead style={{ fontSize: "16px" }}>
            <tr>
              <th>Creator</th>
              <th>Meeting id</th>
              <th>Timing</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody style={{ color: "#202833", fontSize: "14px", backgroundColor: "#F3F3F3" }}>
            <tr>
              <td>1</td>
              <td>jdhhijmoijmp.bfduhtur8uu89uj8</td>
              <td>10:00 AM - 11:00 AM</td>
              <td><button style={styles.joinButton} >Reminder</button></td>
            </tr>
            <tr>
              <td>2</td>
              <td>jdhhijmoijmp.bfduhtur8uu89uj8</td>
              <td>2:00 PM - 3:00 PM</td>
              <td><button style={styles.joinButton}>Reminder</button></td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop:"1px"
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35%',
    gap: 10,
  },
  icon: {
    width: 38,
    height: 38,
    marginRight: 10,
  },
  heading: {
    color: '#001E51',
    fontFamily: 'GothicA1',
    fontSize: 50,
    fontWeight: 700,
  },
  table: {
    width: '80%',
    marginTop: '10px',
  },
  joinButton: {
    width: '80px',
    height: '30px',
    backgroundColor: '#001E51',
    color: "#FFF",
    borderRadius: '6px',
    cursor: 'pointer',
    border: 'none',
  },
  logoutButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '2%',
    marginTop: '1%',
  },

  loginArrowBox: {
    width: '90px',
    height: '38px',
    marginRight: '10px',
    border: '2px solid #001E51',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: "pointer",
  },
  loginArrow: {
    width: '20px',
    height: '20px',
    cursor: "pointer",
  },
  logout: {
    color: "#001E51",
    cursor: "pointer",
    border: "none",
  }, 
 
  
};

export default OwnerLanding;
