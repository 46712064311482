import React, { useEffect, useState } from 'react';
import Dashboarddark from '../images/Dashboardicon.png';
import LoginArrow from '../images/LoginArrow.png';
import { useNavigate } from 'react-router';

const LandingPage = () => {

  const [organizationID, setOrganizationID] = useState('');

  useEffect(() => {
    const fetchedOrganizationID = localStorage.getItem('OrganizationID');
    setOrganizationID(fetchedOrganizationID);
  }, []);

  const handleJoinNow = (meetingId) => {
    console.log(`Join Now clicked for meeting ID: ${meetingId}`);
  };
  const navigate=useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    window.history.pushState(null, null, '/');
    navigate("/");
  }

  return (
    <div style={styles.container}>
    <div style={styles.logoutButtonContainer}>
      <div style={styles.loginArrowBox}>
        <img src={LoginArrow} style={styles.loginArrow} alt="Login Arrow" />
      
      <p style={styles.logout} onClick={handleLogout}> Logout</p>
    </div>
    <p style={styles.organizationID}>OrganizationID:-{organizationID}</p>
    </div>
      <div style={styles.main}>
        <img src={Dashboarddark} style={styles.icon} alt="Demo Icon" />
        <h1 style={styles.heading}>Dashboard</h1>
      </div>
      <div style={{ width: "80%", display: "flex", justifyContent: 'center', alignSelf: "center", flexDirection: "column", color: " #0077B6", fontSize: 22 }}>
        <h2 style={{ marginTop: "1%", fontFamily: "GothicA1", Color: "#0077B6", fontSize: 28 }}>Time Usage</h2>
        <div style={{ width: "48%", display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: "row", gap: "15px" }}>
          <div style={styles.card}>
            <h2>Card Title</h2>
            <p>This is some card content.</p>
          </div>
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "5px" }}>
            <button style={styles.buttondash}>Upgrade Plan?</button>
            <div style={styles.card1}>
              <h6 style={{ fontSize: 14, margin: "0px", width: '100%', justifyContent: 'center', textAlign: "center", textAlign: "center" }}>Remaining</h6>
              <h1 style={{ fontSize: 25, marginTop: "1px", width: '100%', justifyContent: 'center', textAlign: "center", textAlign: "center" }}>60 Hours</h1>
            </div>
            <div style={styles.card2}>
              <h6 style={{ fontSize: 14, margin: "0px", width: '100%', justifyContent: 'center', textAlign: "center", textAlign: "center" }}>Total</h6>
              <h1 style={{ fontSize: 25, marginTop: "1px", width: '100%', justifyContent: 'center', textAlign: "center", textAlign: "center" }}>250 Hours</h1>
            </div>
          </div>
        </div>
        <div>
          <h3 style={{ fontFamily: "GothicA1" }}>My Schedule</h3>
          <table style={styles.table}>
            <thead style={{ fontSize: "18px" }}>
              <tr>
                <th>Creator</th>
                <th>Meeting id</th>
                <th>Timing</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ color: "#202833", fontSize: "14px", backgroundColor: "#F3F3F3" }}>
              

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '2%',
    
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35%',
    gap: 10,
  },
  icon: {
    width: 38,
    height: 38,
    marginRight: 10,
  },
  heading: {
    color: '#001E51',
    fontFamily: 'GothicA1',
    fontSize: 50,
    fontWeight: 700,
  },
  card: {
    height: "200px",
    width: '200px',
    padding: '8px',
    border: 'none solid #001E51',
    borderRadius: '8px',
    margin: '5px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  buttondash: {
    width: '140px',
    height: '40px',
    backgroundColor: '#FCA733',
    color: "#FFF",
    padding: '8px',
    borderRadius: '6px',
    cursor: 'pointer',
    border: 'none',
    marginLeft: "8px"
  },
  card1: {
    width: '130px',
    height: '55px',
    color: "#F3F3F3",
    fontFamily: "Gothic A1",
    backgroundColor: "#001E51",
    padding: '8px',
    border: 'none solid #001E51',
    borderRadius: '4px',
    margin: '5px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  card2: {
    width: '130px',
    height: '55px',
    color: "#202833",
    fontFamily: "Gothic A1",
    backgroundColor: "#F3F3F3",
    padding: '8px',
    border: 'none solid #001E51',
    borderRadius: '4px',
    margin: '5px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  table: {
    width: '80%',
    marginTop: '10px',
  },
  joinButton: {
    width: '80px',
    height: '30px',
    backgroundColor: '#001E51',
    color: "#FFF",
    borderRadius: '6px',
    cursor: 'pointer',
    border: 'none',
  },
  
  logoutButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '2%',
    marginTop: '1%',
    
  },

  loginArrowBox: {
    width: '90px',
    height: '38px',
    marginRight: '10px',
    border: '2px solid #001E51',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginArrow: {
    width: '20px',
    height: '20px',
  },
  logout: {
    color: "#001E51",
    cursor: "pointer",
    border: "none",
  },
 
  
};


export default LandingPage;