import React, { useEffect, useState } from "react";
import Settingdark from "../../images/Settings-dark.png";
import editicon from "../../images/editicon.png";
import TextField from "@mui/material/TextField";

const CustomerDetailView = (props) => {
  const [activeSetting, setActiveSetting] = useState("account");
  const [showInput, setShowInput] = useState(false);
  const [customerData, setCustomerData] = useState({
    CustomerID: "",
    OrganizationID: "",
    FullName: "",
    MobileNumber: "",
    Address: "",
    Email: "",
    UniqueID: "",
    Password: "",
    UserType: "",
    createdAt: "",
    updatedAt: "",
    GST: "",
    AllowedMeetingParticipants: "",
  });

  const handleSettingClick = (setting) => {
    setActiveSetting(setting);
    // Reset password fields when switching settings
    if (setting === "changePasswords") {
      setShowInput(true);
    }
  };

  const handlePasswordClick = () => {
    setShowInput(!showInput);
  };

  const handleSubmit = async () => {
    // You can access the customer data using the 'customerData' state
    // For example, to get the User ID: customerData.CustomerID

    // Your existing code for password change

    try {
      // Use the customer data to make the API call for updating details
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/customer-details/${props.customerId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customerData),
        }
      );

      if (response.ok) {
        console.log("Customer details updated successfully");
        // Add any additional logic you need after successful update
      } else {
        console.error("Error updating customer details");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // Use useEffect to fetch customer details when the component mounts
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/customer-details/${props.customerId}`
        );
        if (response.ok) {
          const data = await response.json();
          setCustomerData(data); // Set the received customer data
          console.log("Customer details fetched successfully", data);
        } else {
          console.error("Error fetching customer details");
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    fetchCustomerDetails();
  }, [props.customerId]);
  return (
    <div style={styles.container}>
      <div style={styles.main}>
        <img src={Settingdark} style={styles.icon} alt="Settings Icon" />
        <h1 style={styles.heading}>Customer Details</h1>
      </div>
      <div style={styles.setting}>
        <h3
          style={
            activeSetting === "account"
              ? styles.activeSetting
              : styles.settingText
          }
          onClick={() => handleSettingClick("account")}
        >
          Account Settings
        </h3>
      </div>
      {activeSetting === "account" && (
        <div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>Customer id</p>
            <TextField
              id="userId"
              variant="standard"
              sx={styles.inputContainer}
              value={customerData.CustomerID}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>Organization ID</p>
            <TextField
              id="OrganizationID"
              variant="standard"
              sx={styles.inputContainer}
              value={customerData.OrganizationID}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>Full name</p>
            <TextField
              id="userName"
              variant="standard"
              sx={styles.inputContainer}
              value={customerData.FullName}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>Mobile No</p>
            <TextField
              id="contactNo"
              variant="standard"
              sx={styles.inputContainer}
              value={customerData.MobileNumber}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>E-mail</p>
            <TextField
              id="email"
              variant="standard"
              sx={styles.inputContainer}
              value={customerData.Email || ""}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>Address</p>
            <TextField
              id="address"
              variant="standard"
              sx={styles.inputContainer}
              value={customerData.Address}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>Subscription Status</p>
            <TextField
              id="subscriptionStatus"
              variant="standard"
              sx={styles.inputContainer}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>Plan Name</p>
            <TextField
              id="text"
              variant="standard"
              sx={styles.inputContainer}
            />
          </div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>GSTIN</p>
            <TextField
              id="text"
              variant="standard"
              sx={styles.inputContainer}
              value={customerData.GST}
            />
          </div>
          <div style={styles.buttonContainer}>
            <img src={editicon} style={styles.icon} alt="" />
            <button
              type="submit"
              style={styles.submitButton}
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button type="submit" style={styles.cancelButton}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {activeSetting === "general" && (
        <div>
          <div style={styles.general}>
            <h4 onClick={() => handlePasswordClick("changePassword")}>
              Change Password
            </h4>
            {showInput && (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  marginLeft: "70%",
                  flexDirection: "column",
                }}
              >
                <input
                  type="password"
                  id="currentPassword"
                  style={{
                    marginBottom: "15px",
                    border: "2px solid #001E51",
                    borderRadius: "4px",
                    fontFamily: "GothicA1",
                    color: "#999",
                    fontSize: 13,
                  }}
                  placeholder="Current Password"
                  required
                />

                <div>
                  <input
                    type="password"
                    id="newPassword"
                    style={{
                      marginBottom: "15px",
                      border: "2px solid #001E51",
                      borderRadius: "4px",
                      fontFamily: "GothicA1",
                      color: "#999",
                      fontSize: 13,
                    }}
                    placeholder="New Password"
                    required
                  />
                </div>

                <div>
                  <input
                    type="password"
                    id="confirmPassword"
                    style={{
                      marginBottom: "15px",
                      border: "2px solid #001E51",
                      borderRadius: "4px",
                      fontFamily: "GothicA1",
                      color: "#999",
                      fontSize: 13,
                    }}
                    placeholder="Re-Enter Password"
                    required
                  />
                </div>
                <div style={styles.buttonContainer}>
                  <button
                    type="submit"
                    style={styles.submitButton}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button type="submit" style={styles.cancelButton}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
            <h4 style={{ marginBottom: 1, marginRight: 70 }}>Privacy</h4>
            <h4 style={{ marginBottom: 1 }}>Delete Account</h4>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    height: "100%",
    margin: "2%",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "35%",
    gap: 10,
  },
  icon: {
    width: 38,
    height: 38,
    marginRight: 10,
  },
  heading: {
    color: "#001E51",
    fontFamily: "GothicA1",
    fontSize: 50,
    fontWeight: 700,
  },
  setting: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    gap: 90,
    marginBottom: 20,
  },
  settingText: {
    color: "#001E51",
    fontFamily: "Gothic A1",
    fontSize: 20,
    fontWeight: "bold",
    cursor: "pointer",
  },
  activeSetting: {
    color: "#0077B6",
    fontFamily: "Gothic A1",
    fontSize: 20,
    fontWeight: "bold",
    borderBottom: "2px solid #001E51",
    paddingBottom: "5px",
    cursor: "pointer",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "70%",
    gap: 20,
    fontFamily: "Gothic A1",
    fontSize: 20,
    fontWeight: "bold",
    color: "#001E51",
  },
  label: {
    margin: 0,
    color: "#001E51",
    fontSize: 16,
    width: "17%",
    fontFamily: "Gothic A1",
  },
  inputContainer: {
    width: "40%",
    padding: "8px",
    whiteSpace: "pre-wrap",
    textAlign: "left",
    direction: "ltr",
    color: "black",
    fontSize: 16,
    margin: 0,
  },
  general: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    color: "#001E51",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    alignSelf: "center",
    marginTop: 15,
    marginLeft: "14%",
  },
  submitButton: {
    color: "#001E51",
    padding: "4px 17px",
    borderRadius: "6px",
    cursor: "pointer",
    border: "2px solid #001E51",
  },
  cancelButton: {
    color: "#001E51",
    padding: "4px 17px",
    borderColor: "#001E51",
    borderRadius: "6px",
    cursor: "pointer",
    border: "2px solid #001E51",
  },
};

export default CustomerDetailView;
