import React, { useEffect, useState } from 'react';
import Arrow from '../../images/Arrow.png';

function AddMeetings(props) {
  const [meetingData, setMeetingData] = useState({
    MeetingName: '',
    StartDate: '',
    EndDate:'',
    StartTime: '',
    EndTime: '',
    Duration: '',
    MaxMembersJoined: '',
    MeetingCreatorName: '',
    Participants: [], // Updated to store selected users
    OrganizationID: '',
    UniqueID: ''
  });

  const OrganizationID = localStorage.getItem('OrganizationID');

  const [error, setError] = useState('');

  const [users, setUsers] = useState([]); // State to store user data

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      height: '100%',
      margin: '1%',
    },
  }

  useEffect(() => {
    // Load values from local storage when the component mounts
    const organizationID = localStorage.getItem('OrganizationID');
    const meetingCreatorName = localStorage.getItem('FullName');
    const uniqueID = localStorage.getItem('UniqueID');

    // Set non-editable values
    setMeetingData((prevData) => ({
      ...prevData,
      OrganizationID: organizationID,
      MeetingCreatorName: meetingCreatorName,
      UniqueID: uniqueID,
    }));

    // Fetch users from the API
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/organization/${OrganizationID}`);
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'StartDateTime' || name === 'EndDateTime') {
      // If it's the combined date-time field
      const [date, time] = value.split('T'); // Split date and time
      const dateName = name === 'StartDateTime' ? 'StartDate' : 'EndDate'; // Determine if it's start or end
      setMeetingData(prevData => ({
        ...prevData,
        [dateName]: date,
        [dateName === 'StartDate' ? 'StartTime' : 'EndTime']: time,
        Duration: calculateDuration(prevData.StartDate, prevData.StartTime, date, time)
      }));
    } else if (name === 'Participants') {
      // If it's the participants field (checkboxes)
      const isChecked = e.target.checked;
      const UserID = e.target.value;
  
      // Update selected participants based on checkbox status
      if (isChecked) {
        setMeetingData(prevData => ({
          ...prevData,
          Participants: [...prevData.Participants, UserID],
        }));
      } else {
        setMeetingData(prevData => ({
          ...prevData,
          Participants: prevData.Participants.filter(id => id !== UserID),
        }));
      }
    } else {
      // For other fields, update directly
      setMeetingData({ ...meetingData, [name]: value });
    }
    setError(''); // Clear any existing error when the user changes the input
  };
  
  const calculateDuration = (startDate, startTime, endDate, endTime) => {
    const start = new Date(`${startDate}T${startTime}`);
    const end = new Date(`${endDate}T${endTime}`);
    const duration = Math.abs(end - start) / 60000; // Calculate difference in minutes
    return duration;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/customerMeetings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(meetingData),
      });

      if (response.ok) {
        // console.log('Meeting data submitted successfully');
        alert('Meeting data submitted successfully');
        handleGoBack();
        // You can add code to reset the form or show a success message here
      } else {
        console.error('Error submitting meeting data');
      }
    } catch (error) {
      console.error('Error submitting meeting data:', error);
    }
  };

  const handleGoBack = () => {
    props.setActive(2);
  };

  return (
    <>
    <div style={styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: "90%", gap: "15px", marginBottom: '6px' }}>
          <img src={Arrow} style={{ cursor: "pointer" }} onClick={handleGoBack} alt="Back" />
          <h2 style={{ fontWeight: 'bold', fontSize: 30, color: '#001E51', justifyContent: "center", alignItem: "center", width: "40%" }}>New Meetings</h2>
        </div>
        <form onSubmit={handleSubmit} style={{ width: '50%', maxWidth: '30%', fontWeight: 'bold' }}>
           {/* Non-editable fields */}
           <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Organization ID</label>
            <input
              type="text"
              value={meetingData.OrganizationID}
              disabled
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #ccc', backgroundColor: '#f4f4f4' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>UniqueID</label>
            <input
              type="text"
              value={meetingData.UniqueID}
              disabled
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #ccc', backgroundColor: '#f4f4f4' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Meeting Creator Name</label>
            <input
              type="text"
              value={meetingData.MeetingCreatorName}
              disabled
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #ccc', backgroundColor: '#f4f4f4' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Room Name </label>
            <input
              type="text"
              name="MeetingName"
              value={meetingData.MeetingName}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Start Date & Time</label>
            <input
              type="datetime-local"
              name="StartDateTime"
              value={meetingData.StartDate ? `${meetingData.StartDate}T${meetingData.StartTime}` : ''}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>End Date & Time</label>
            <input
              type="datetime-local"
              name="EndDateTime"
              value={meetingData.EndDate ? `${meetingData.EndDate}T${meetingData.EndTime}` : ''}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1%', alignItems: 'center' }}>
          <label style={{ color: '#001E51', marginBottom: '8px', width: '30%', fontSize: 16 }}>Max Members Joined</label>
          <input
            type="number"
            name="MaxMembersJoined"
            value={meetingData.MaxMembersJoined}
            onChange={handleChange}
            required
            style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
          />
        </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1%', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '8px', width: '30%', fontSize: 16 }}>Duration in Min</label>
            <input
              type="number"
              name="Duration"
              value={meetingData.Duration}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          {/* User selection with checkboxes */}
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '8px', alignItems: 'left' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', fontSize: 16 }}>Participants Modrator</label>
            {users?.map(user => (
              <div key={user.UserID} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  id={`user-${user.UserID}`}
                  name="Participants"
                  value={user.UserID}
                  onChange={handleChange}
                />
                <label htmlFor={`user-${user.UserID}`} style={{ marginLeft: '5px' }}>{user.Email}</label>
              </div>
            ))}
          </div>

          {/* ... additional form fields ... */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            <button type="submit" style={{ backgroundColor: '#001E51', color: 'white', padding: '8px 80px', border: 'none', borderRadius: '6px', cursor: 'pointer', margin: 20 }}>Submit</button>
            <button onClick={handleGoBack} style={{ color: '#001E51', padding: '8px 80px', borderRadius: '6px', cursor: 'pointer', margin: 20 }}>Cancel</button>
          </div>
        </form>
      </div>
      </div>
    </>
  );
}

export default AddMeetings;
