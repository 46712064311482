import React, { useEffect, useState } from 'react';
import Arrow from '../../images/Arrow.png';

function AddSubscriptions(props) {
  const [subscriptionData, setSubscriptionData] = useState({
    SubscriptionID: '',
    SubscriptionAmount: '',
    AllowedMinutes: '',
    AllowedMeetingParticipants: '',
    SubscriptionPlanName: '',
  });

  const [error, setError] = useState('');

  useEffect(() => {
    // Load values from local storage or other sources when the component mounts
    // Example: Load OrganizationID, SubscriptionCreatorName, UniqueID, etc.
    // const organizationID = localStorage.getItem('OrganizationID');
    // const subscriptionCreatorName = localStorage.getItem('FullName');
    // const uniqueID = localStorage.getItem('UniqueID');

    // Set non-editable values
    setSubscriptionData((prevData) => ({
      ...prevData,
      // OrganizationID: organizationID,
      // SubscriptionCreatorName: subscriptionCreatorName,
      // UniqueID: uniqueID,
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionData({ ...subscriptionData, [name]: value });
    setError(''); // Clear any existing error when the user changes the input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-subscriptions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscriptionData),
      });

      if (response.ok) {
        console.log('Subscription data submitted successfully');
        alert('Subscription data submitted successfully');
        handleGoBack();
        // You can add code to reset the form or show a success message here
      } else {
        console.error('Error submitting subscription data');
      }
    } catch (error) {
      console.error('Error submitting subscription data:', error);
    }
  };

  const handleGoBack = () => {
    props.setActive(11);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: "90%", gap: "15px", marginBottom: '6px' }}>
          <img src={Arrow} style={{ cursor: "pointer" }} onClick={handleGoBack} alt="Back" />
          <h2 style={{ fontWeight: 'bold', fontSize: 30, color: '#001E51', justifyContent: "center", alignItem: "center", width: "40%" }}>New Subscriptions</h2>
        </div>
        <form onSubmit={handleSubmit} style={{ width: '50%', maxWidth: '30%', fontWeight: 'bold' }}>
          {/* Non-editable fields */}
          {/* ... OrganizationID, SubscriptionCreatorName, UniqueID, etc. fields ... */}
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Subscription ID</label>
            <input
              type="number"
              name="SubscriptionID"
              value={subscriptionData.SubscriptionID}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Subscription Amount</label>
            <input
              type="text"
              name="SubscriptionAmount"
              value={subscriptionData.SubscriptionAmount}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          {/* New fields */}
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Allowed Minutes</label>
            <input
              type="number"
              name="AllowedMinutes"
              value={subscriptionData.AllowedMinutes}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Allowed Meeting Participants</label>
            <input
              type="number"
              name="AllowedMeetingParticipants"
              value={subscriptionData.AllowedMeetingParticipants}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>Subscription Plan Name</label>
            <input
              type="text"
              name="SubscriptionPlanName"
              value={subscriptionData.SubscriptionPlanName}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          {/* ... additional subscription fields ... */}

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            <button type="submit" style={{ backgroundColor: '#001E51', color: 'white', padding: '8px 80px', border: 'none', borderRadius: '6px', cursor: 'pointer', margin: 20 }}>Submit</button>
            <button onClick={handleGoBack} style={{ color: '#001E51', padding: '8px 80px', borderRadius: '6px', cursor: 'pointer', margin: 20 }}>Cancel</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddSubscriptions;
