import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Sidebar from "../Admin/AdminSidebar";
import LandingPage from "../../pages/LandingPage";
import MeetingsPage from "./Meetings";
import AddMeetings from "./AdminAddMeetings";
import UsersList from "./UsersList";
import Seetings from "./Settings";
import AddUsers from "./AddUser";

export default function Dashboard(props) {
    const [active, setActive] = useState(1);
    useEffect(()=>{
        if(props.setActive){
        setActive(props.setActive)
        }
    },[props])
    console.log("props",props)
    const handleClick = (key) => {
      setActive(key);
    };

let content = null;
  if (active === 1) {
    content = <LandingPage/>
  } else if (active=== 2 ) {
    content = <MeetingsPage setActive={setActive}/>;
  }
  else if (active === 4) {
    content =<LandingPage/>;
  } 
  else if (active=== 6 ) {
    content = <UsersList setActive={setActive}/>;
  } 
  else if (active=== 3 ) {
    content = <AddMeetings setActive={setActive} />;
  } 
  else if (active=== 7 ) {
    content = <Seetings />;
  } 
  else if (active=== 8 ) {
    content = <AddUsers setActive={setActive}/>;
  } 
  
  else {
    content = <LandingPage/>
  }
  return (
    <>
      <Box sx={styles.mainContainer}>
        {/* ..............navbar */}
        <Box>
        </Box>

        <Box sx={styles.bottomContainer}>
          <Box sx={styles.sidebar}>
            <Sidebar active={active} setActive={setActive} handleClick={handleClick} />
          </Box>
          <Box sx={styles.centerPart}>
          {content}
          </Box>
        </Box>
      </Box>
    </>
  );
}

const styles = {
  mainContainer: {
    width: "100%",
    overflowY: "hidden",
    height: "100%"
    // display:"flex",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",

    '@media (max-width: 768px)':{
      flexWrap:"wrap",
    }
  },
  sidebar: {
    width: "15%",
    backgroundColor:"darkblue",
    height:"100vh",
    '@media (max-width: 768px)':{
      height:'auto',
    }

  },
  centerPart: {
    width: "85%",
    overflowX:'hidden',

  },
};