import React, { useState, useEffect } from 'react';
import '../../styles/meetings.css'; // External CSS file
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import usageLogo from '../../images/usage-dark.png'

const MeetingUsageLogs = () => {
  const navigate = useNavigate();
  const [meetingUsageLogs, setMeetingUsageLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 7;
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      height: '110%',
      margin: '2%',
    },
  }
  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${process.env.REACT_APP_API_URL}/api/meeting-usage-logs/`)
      .then((response) => response.json())
      .then((data) => setMeetingUsageLogs(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Calculate current logs to display based on pagination
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = meetingUsageLogs.slice(indexOfFirstLog, indexOfLastLog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={styles.container}>
    <div className="meetings-page">
      <header>
        <div className='meetings-header-title'>
        <img src={usageLogo} alt=''></img>

          <h1 className='title'>Meeting Usage Logs</h1>
        </div>
      </header>
      <div>
        <h3>Meeting Usage Logs List</h3>
        <table>
          <thead>
            <tr>
              <th>OrganizationID</th>
              <th>CustomerMeetingID</th>
              <th>UsageTime</th>
              <th>LeaveButtonClickTime</th>
              <th>JoinButtonClickTime</th>
              <th>DisplayName</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {currentLogs?.map((log) => (
              <tr key={log.LogID}>
                <td>{log.OrganizationID}</td>
                <td>{log.CustomerMeetingID}</td>
                <td>{log.UsageTime}</td>
                <td>{log.LeaveButtonClickTime}</td>
                <td>{log.JoinButtonClickTime}</td>
                <td>{log.DisplayName}</td>
                <td>{log.Email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {[...Array(Math.ceil(meetingUsageLogs.length / logsPerPage)).keys()].map((number) => (
            <button key={number + 1} onClick={() => paginate(number + 1)}>
              {number + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default MeetingUsageLogs;
