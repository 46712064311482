import React, { useState, useEffect } from "react";
import "../../styles/meetings.css"; // External CSS file
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import axios from 'axios';

const MeetingsPage = (props) => {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState([]);
  const [editingMyMeetingId, setEditingMyMeetingId] = useState(null);
  const [editingOngoingMeetingId, setEditingOngoingMeetingId] = useState(null);
  const organizationID = localStorage.getItem("OrganizationID");
  const Name = localStorage.getItem("FullName");
  const Email = localStorage.getItem("userEmail")

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [moderatorUrl, setModeratorUrl] = useState("");

  const [editedMeeting, setEditedMeeting] = useState({
    MeetingName: "",
    MeetingCreatorName: "",
    StartDate: "",
    StartTime: "",
    Duration: "",
    Participants: "",
  });

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/meetings/organization/${organizationID}`
    )
      .then((response) => response.json())
      .then((data) => setMeetings(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // useEffect(() => {
  
  //   // Call handleJoinNow when component mounts or when meetingId changes
  //   if (meetings.CustomerMeetingID) {
  //     handleJoinNow();
  //   }
  // }, [meetings.CustomerMeetingID]);
  const handleJoinNow = async (id) => {
    try {
      const payload = {
        CustomerMeetingID: id,
        organizationID: Number(organizationID), // Replace 'YourOrganizationID' with your actual organization ID
        displayName: Name,
        email: Email,
      };

      console.log(payload,'pajdoajf')
      // Send a POST request to the endpoint
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/join-meeting/${id}`,
        payload
      );
      console.log(response.data,'resdata')
      // Assuming your API returns the moderator URL in the response
      const moderatorUrl = response.data.ModeratorUrl;
      setModeratorUrl(moderatorUrl);

      // Open the modal
      setModalIsOpen(true);
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };


  const handleEdit = (meetingId, section) => {
    if (section === "myMeetings") {
      setEditingMyMeetingId(meetingId);
    } else if (section === "ongoingMeetings") {
      setEditingOngoingMeetingId(meetingId);
    }

    const editedMeetingData = meetings.find(
      (meeting) => meeting.MeetingID === meetingId
    );
    setEditedMeeting({
      MeetingName: editedMeetingData.MeetingName,
      MeetingCreatorName: editedMeetingData.MeetingCreatorName,
      StartDate: editedMeetingData.StartDate,
      StartTime: editedMeetingData.StartTime,
      Duration: editedMeetingData.Duration,
      MemberUrl: editedMeetingData.MemberUrl,
      Participants: editedMeetingData.Participants,
    });
  };

  const handleSave = (meetingId) => {
    const updatedMeetings = meetings?.map((meeting) => {
      if (meeting.MeetingID === meetingId) {
        return {
          ...meeting,
          MeetingName: editedMeeting.MeetingName,
          MeetingCreatorName: editedMeeting.MeetingCreatorName,
          StartDate: editedMeeting.StartDate,
          StartTime: editedMeeting.StartTime,
          Duration: editedMeeting.Duration,
          Participants: editedMeeting.Participants,
        };
      }
      return meeting;
    });

    setMeetings(updatedMeetings);

    fetch(`${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        updatedMeetings.find((meeting) => meeting.MeetingID === meetingId)
      ),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update meeting in the database");
        }
      })
      .catch((error) => console.error("Error updating meeting:", error));

    setEditingMyMeetingId(null);
    setEditingOngoingMeetingId(null);
  };

  const handleCancel = () => {
    setEditedMeeting({
      MeetingName: "",
      MeetingCreatorName: "",
      StartDate: "",
      StartTime: "",
      Duration: "",
      Participants: "",
    });
    setEditingMyMeetingId(null);
    setEditingOngoingMeetingId(null);
  };

  const handleDelete = (meetingId, section) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this meeting?"
    );
    if (isConfirmed) {
      setMeetings(
        meetings.filter((meeting) => meeting.CustomerMeetingID !== meetingId)
      );

      fetch(
        `${process.env.REACT_APP_API_URL}/api/customerMeetings/${meetingId}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to delete meeting from the database");
          }
        })
        .catch((error) => console.error("Error deleting meeting:", error));
    }
  };

  const handleInputChange = (field, value) => {
    setEditedMeeting((prevEditedMeeting) => ({
      ...prevEditedMeeting,
      [field]: value,
    }));
  };

  return (
    <div className="main-box">
      <div className="meetings-page">
        <header>
          <h1 className="title">Admin Meetings</h1>
          <button
            onClick={() => props.setActive(3)}
            className="new-meeting-btn"
          >
            + New Meeting
          </button>
        </header>
        <div>
          <h3>My Meetings</h3>
          <table>
            <thead>
              <tr>
                <th>Meeting ID</th>
                <th>Room Name</th>
                <th>Created by</th>
                <th>Date and Time</th>
                <th>Duration</th>
                <th>Member URL</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {meetings?.map((meeting) => (
                <tr key={meeting.CustomerMeetingID}>
                  <td>{meeting.CustomerMeetingID}</td>
                  <td>
                    {editingMyMeetingId === meeting.CustomerMeetingID ? (
                      <input
                        type="text"
                        value={editedMeeting.MeetingName}
                        onChange={(e) =>
                          handleInputChange("MeetingName", e.target.value)
                        }
                      />
                    ) : (
                      meeting.MeetingName
                    )}
                  </td>
                  <td>
                    {editingMyMeetingId === meeting.CustomerMeetingID ? (
                      <input
                        type="text"
                        value={editedMeeting.MeetingCreatorName}
                        onChange={(e) =>
                          handleInputChange(
                            "MeetingCreatorName",
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      meeting.MeetingCreatorName
                    )}
                  </td>
                  <td>
                    {editingMyMeetingId === meeting.CustomerMeetingID ? (
                      <>
                        <input
                          type="date"
                          value={editedMeeting.StartDate}
                          onChange={(e) =>
                            handleInputChange("StartDate", e.target.value)
                          }
                        />
                        <input
                          type="time"
                          value={editedMeeting.StartTime}
                          onChange={(e) =>
                            handleInputChange("StartTime", e.target.value)
                          }
                        />
                      </>
                    ) : (
                      `${meeting.StartDate} ${meeting.StartTime}`
                    )}
                  </td>
                  <td>
                    {editingMyMeetingId === meeting.CustomerMeetingID ? (
                      <input
                        type="text"
                        value={editedMeeting.Duration}
                        onChange={(e) =>
                          handleInputChange(
                            "Duration",
                            e.target.value.replace(/\D/, "")
                          )
                        }
                      />
                    ) : (
                      meeting.Duration
                    )}
                  </td>
                  <td>
                    {editingMyMeetingId === meeting.CustomerMeetingID ? (
                      <input
                        type="text"
                        value={editedMeeting.Participants}
                        onChange={(e) =>
                          handleInputChange("Participants", e.target.value)
                        }
                      />
                    ) : (
                      meeting.MemberUrl
                    )}
                  </td>
                  <td>
                    {editingMyMeetingId === meeting.CustomerMeetingID ? (
                      <>
                        <button
                          onClick={() => handleSave(meeting.CustomerMeetingID)}
                        >
                          Save
                        </button>
                        <button onClick={handleCancel}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => handleJoinNow(meeting.CustomerMeetingID)}
                        >
                          Join Now
                        </button>
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={() => setModalIsOpen(false)}
                          contentLabel="JitsiSdk Modal"
                          shouldCloseOnOverlayClick={false}
                        >
                          <button
                            onClick={() => setModalIsOpen(false)}
                            className="close-button"
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                            }}
                          >
                            X
                          </button>
                          <iframe
                            src={moderatorUrl}
                            title="Moderator URL"
                            allow="camera; microphone"
                            style={{
                              width: "95%",
                              height: "81vh",
                              margin: "none",
                            }}
                          ></iframe>
                        </Modal>
                        {/* <button onClick={() => handleDelete(meeting.CustomerMeetingID)}>Delete</button> */}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MeetingsPage;
