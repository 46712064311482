// IframeDemo.js
import React, { useState } from 'react';
import '../../styles/IframeDemo.css'; // External CSS file


const IframeDemo = () => {
  const [roomName, setRoomName] = useState('');
  const [iframeUrls] = useState([
    'https://advance.convenevc.com/',
    'https://advance.convenevc.com/',
    'https://advance.convenevc.com/',
    'https://advance.convenevc.com/',
  ]);

  const handleInputChange = (e) => {
    setRoomName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your logic to handle the form submission, e.g., redirect to a new page with the roomName
    console.log(`Room name submitted: ${roomName}`);
  };

  return (
    <div className="room-page">
      <div className="input-box-container">
        <form onSubmit={handleSubmit}>
          <label>
            Room Name:
            <input type="text" value={roomName} onChange={handleInputChange} />
          </label>
          <button className='ibutton' type="submit">Submit</button>
        </form>
      </div>
      <div className="iframe-box-container">
        {iframeUrls.map((url, index) => (
          <div key={index} className="iframe-box">
            <iframe title={`iframe-${index}`} src={roomName ? `${url}/${roomName}` : url} frameBorder="0"></iframe>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IframeDemo;
