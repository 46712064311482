import { useState } from "react";

const CustomizedUrl = () => {
  const [roomName, setRoomName] = useState('');
  const [moderatorUrl, setModeratorUrl] = useState('');
  const [memberUrl, setMemberUrl] = useState('');
  const apiEndpoint = process.env.REACT_APP_API_URL;

  const handleRoomNameChange = (e) => {
    setRoomName(e.target.value);
  };

  const generateUrls = async () => {
    try {
      const response = await fetch(`${apiEndpoint}/api/my-meeting?MeetingRoomName=${roomName}`, {
        method: 'GET',
      });
  
      if (response.ok) {
        const data = await response.json();
        setModeratorUrl(data.ModeratorUrl);
        setMemberUrl(data.MemberUrl);
      } else {
        console.error('Failed to generate URLs');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h2 style={{ marginBottom: "10px" }}>Generate URLs for Moderator and Member</h2>
      <label style={{ marginBottom: "10px" }}>
        Room Name:
        <input type="text" value={roomName} onChange={handleRoomNameChange} />
      </label>
      <button style={{ marginBottom: "10px" }} onClick={generateUrls}>Generate URLs</button>

      {moderatorUrl && memberUrl && (
        <div style={{ marginTop: "160px", display: "flex", flexDirection: "column", alignItems: "left", width: '300px' }}>
          <h3 style={{ marginTop: "140px", display: "flex", flexDirection: "column", alignItems: "left" }}>Generated URLs:</h3>
          <p>
            Moderator URL: 
            <a href={moderatorUrl} target="_blank" rel="noopener noreferrer" style={{ wordWrap: 'break-word' }}>
              {moderatorUrl}
            </a>
          </p>
          <p>
            Member URL: 
            <a href={memberUrl} target="_blank" rel="noopener noreferrer">
              {memberUrl}
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomizedUrl;
