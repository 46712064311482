import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../styles/meetings.css'; // External CSS file
import { useNavigate } from 'react-router-dom';
import MeetingsIcon from '../../images/meetings-dark.png';
import JitsiSdk from '../../components/JitsiSdk';
import axios from 'axios';


const OwnerMeetings = (props) => {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const organizationID = localStorage.getItem('OrganizationID');
  const displayName = localStorage.getItem('FullName')
  const userEmail = localStorage.getItem('userEmail')

  useEffect(() => {
    // Fetch data from the API when the component mounts
    axios.get(`${process.env.REACT_APP_API_URL}/api/meetings/organization/${organizationID}`)
      .then((response) => setMeetings(response.data))
      .catch((error) => console.error('Error fetching data:', error));
  }, [organizationID]); // Dependency array to re-run the effect when organizationID changes

  const handleJoinNow = (meeting) => {
    // Set modal to open
    setModalIsOpen(true);
    // Pass the meeting name to the JitsiSdk component
    setSelectedMeeting(meeting);
  };

  const handleDelete = (meetingId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this meeting?');
    if (isConfirmed) {
      // Update the state first to reflect the change locally
      setMeetings(meetings.filter((meeting) => meeting.CustomerMeetingID !== meetingId));

      // Make the API call to delete the meeting from the database
      axios.delete(`${process.env.REACT_APP_API_URL}/api/customerMeetings/${meetingId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to delete meeting from the database');
          }
          window.location.reload(); // Reload the page after successful deletion
        })
        .catch((error) => console.error('Error deleting meeting:', error));
    }
  };


  const fetchToken = async () => {
    const response = await fetch('https://token.convenevc.com/generate-jwt');
    const data = await response.json();
    // console.log(data.token)
    return data.token;
    
  };

  const handleCopyUrl = async (url, isModerator) => {
    let finalUrl = url;

    if (isModerator) {
      try {
        const token = await fetchToken();
        finalUrl = `${url}?jwt=${token}`;
      } catch (error) {
        console.error('Error fetching token: ', error);
        alert('Failed to fetch token for Moderator URL');
        return;
      }
    }

    navigator.clipboard.writeText(finalUrl).then(() => {
      alert('URL copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleViewDetails = (CustomerMeetingID) => {
    // ... (unchanged)
  };

  return (
    <div className="meetings-page">
      <header>
        <div className="meetings-header-title">
          <img src={MeetingsIcon} alt=""></img>
          <h1 className="title">Meetings</h1>
        </div>
        <button onClick={() => { props.setActive(5) }} className="new-meeting-btn">+ New Meeting</button>
      </header>
      <table>
        <thead>
          <tr>
            <th>Meeting ID</th>
            <th>Room Name</th>
            <th>Created by</th>
            <th>Date and Time</th>
            <th>Duration</th>
            <th>Member URL / Modrator URL</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {meetings.map((meeting) => (
            <tr key={meeting.CustomerMeetingID}>
              <td>{meeting.CustomerMeetingID}</td>
              <td>{meeting.MeetingName}</td>
              <td>{meeting.MeetingCreatorName}</td>
              <td>{`${meeting.StartDate} ${meeting.StartTime}`}</td>
              <td>{meeting.Duration}</td>
              <td>
              <button onClick={() => handleCopyUrl(meeting.MemberUrl, false)}>Copy Member URL</button>
              <button onClick={() => handleCopyUrl(meeting.ModeratorUrl, true)}>Copy Moderator URL</button>
            </td>
              <td>
                <button onClick={() => handleJoinNow(meeting)}>Join Now</button>
                <button onClick={() => handleDelete(meeting.CustomerMeetingID)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Modal for displaying JitsiSdk */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="JitsiSdk Modal"
        shouldCloseOnOverlayClick={false}
      >
        <button onClick={() => setModalIsOpen(false)} className="close-button"style={{ position: 'absolute', top: '10px', right: '10px' }}>X</button> {/* Add a close button */}
       <div style={{ width: '95%', height: '81vh', margin: 'none' }}>
        <JitsiSdk displayName={displayName} userEmail={userEmail} meetingName={selectedMeeting?.MeetingName} />
      </div>
      </Modal>
    </div>
  );
};

export default OwnerMeetings;
