import React, { useState, useEffect } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import AdminDashboard from './pages/Admin/AdminDashboard';
import Login from './pages/Login';
import MeetingsPage from './pages/Admin/Meetings';
import Dashboard from './pages/Dashbord';

function NotFound() {
  return <h1>404 Not Found</h1>;
}

function App() {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem('authenticated'));

  // Define a function to handle authentication status changes
  const handleAuthentication = (isAuthenticated) => {
    setAuthenticated(isAuthenticated);
  };

  useEffect(() => {
    // Check authentication status on component mount
    const isAuthenticated = localStorage.getItem('authenticated');
    setAuthenticated(isAuthenticated);
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login onAuthentication={handleAuthentication} />} />
        <Route path="/meetings" element={authenticated ? <MeetingsPage /> : <Navigate to="/login" replace />} />
        <Route
          path="/admin-dashboard"
          element={authenticated ? <AdminDashboard /> : <Navigate to="/login" replace />}
        />
        {/* <Route path="/create-meeting" element={authenticated ? <AddMeetings /> : <Navigate to="/login" replace />} /> */}
        <Route
          path="/owner-dashboard"
          element={authenticated ? <Dashboard /> : <Navigate to="/login" replace />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
