// CustomersPage.js
import React, { useState, useEffect } from 'react';
import '../../styles/meetings.css'; // External CSS file
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MeetingsIcon from '../../images/customer-dark.png';

const CustomersList = (props) => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [editingCustomerId, setEditingCustomerId] = useState(null);
  const [editedCustomer, setEditedCustomer] = useState({
    Name: '',
    SubscriptionStatus: '',
    StartDate: '',
    EndDate: '',
    SubscriptionType: '',
    MinutesUsed: '',
  });

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${process.env.REACT_APP_API_URL}/api/customer-details/`)
      .then((response) => response.json())
      .then((data) => setCustomers(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []); // Empty dependency array to run the effect only once when the component mounts

  const handleViewDetails = (customerId) => {
    // Implement your logic to view details of a specific customer
    props.setCustomerId(customerId)
    props.setActive(9)
    console.log('View Details of Customer ID:', customerId);
  };

  const handleEdit = (customerId) => {
    setEditingCustomerId(customerId);

    // Set initial values for the edited customer
    const editedCustomerData = customers.find((customer) => customer.CustomerID === customerId);
    setEditedCustomer({
      Name: editedCustomerData.Name,
      SubscriptionStatus: editedCustomerData.SubscriptionStatus,
      StartDate: editedCustomerData.StartDate,
      EndDate: editedCustomerData.EndDate,
      SubscriptionType: editedCustomerData.SubscriptionType,
      MinutesUsed: editedCustomerData.MinutesUsed,
    });
  };

  const handleSave = (customerId) => {
    // Implement your logic to save the changes
    const updatedCustomers = customers?.map((customer) => {
      if (customer.CustomerID === customerId) {
        // Update the edited customer
        return {
          ...customer,
          Name: editedCustomer.Name,
          SubscriptionStatus: editedCustomer.SubscriptionStatus,
          StartDate: editedCustomer.StartDate,
          EndDate: editedCustomer.EndDate,
          SubscriptionType: editedCustomer.SubscriptionType,
          MinutesUsed: editedCustomer.MinutesUsed,
        };
      }
      return customer;
    });

    setCustomers(updatedCustomers);

    // Make the API call to update the customer in the database
    fetch(`${process.env.REACT_APP_API_URL}//api/customer-details/${customerId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        updatedCustomers.find((customer) => customer.CustomerID === customerId)
      ),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update customer in the database');
        }
      })
      .catch((error) => console.error('Error updating customer:', error));
    setEditingCustomerId(null);
  };

  const handleCancel = () => {
    setEditedCustomer({
      Name: '',
      SubscriptionStatus: '',
      StartDate: '',
      EndDate: '',
      SubscriptionType: '',
      MinutesUsed: '',
    });
    setEditingCustomerId(null);
  };

  const handleDelete = (customerId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this customer?');
    if (isConfirmed) {
      setCustomers(customers.filter((customer) => customer.CustomerID !== customerId));

      fetch(`${process.env.REACT_APP_API_URL}//api/customer-details/${customerId}`, {
        method: 'DELETE',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to delete customer from the database');
          }
        })
        .catch((error) => console.error('Error deleting customer:', error));
    }
  };

  const handleInputChange = (field, value) => {
    // Update the state for the edited customer
    setEditedCustomer((prevEditedCustomer) => ({
      ...prevEditedCustomer,
      [field]: value,
    }));
  };

  return (
    <div className="meetings-page">
    <header>
      <div className='meetings-header-title'>
        <img src={MeetingsIcon} alt=''></img>
        <h1 className='title'>Customers</h1>
      </div>
      <button onClick={() => { props.setActive(8) }} className="new-meeting-btn">+ Add Customer</button>
    </header>
    <div>
      <h3>Customers List</h3>
      <table>
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Name</th>
            <th>Subscription Status</th>
            <th>Minutes Used</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {customers?.map((customer) => (
            <tr key={customer.CustomerID}>
              <td>{customer.CustomerID}</td>
              <td>
                {editingCustomerId === customer.CustomerID ? (
                  <input
                    type="text"
                    value={editedCustomer.FullName}
                    onChange={(e) => handleInputChange('FullName', e.target.value)}
                  />
                ) : (
                  customer.FullName
                )}
              </td>
              <td>
                {editingCustomerId === customer.CustomerID ? (
                  <input
                    type="text"
                    value={editedCustomer.SubscriptionStatus}
                    onChange={(e) => handleInputChange('SubscriptionStatus', e.target.value)}
                  />
                ) : (
                  customer.SubscriptionStatus
                )}
              </td>
              <td>
                {editingCustomerId === customer.CustomerID ? (
                  <input
                    type="text"
                    value={editedCustomer.MinutesUsed}
                    onChange={(e) => handleInputChange('MinutesUsed', e.target.value.replace(/\D/, ''))}
                  />
                ) : (
                  customer.MinutesUsed
                )}
              </td>
              <td>
                {editingCustomerId === customer.CustomerID ? (
                  <>
                    <button onClick={() => handleSave(customer.CustomerID)}>Save</button>
                    <button onClick={handleCancel}>Cancel</button>
                  </>
                ) : (
                  <>
                    <Link onClick={() => handleViewDetails(customer.CustomerID)}>View Details</Link>
                    <button onClick={() => handleDelete(customer.CustomerID)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default CustomersList;
