import React, { useState } from 'react';
import Arrow from '../../images/Arrow.png';
import Dashboard from '../../pages/Dashbord';

function AddUsers(props) {
  // Fetch OrganizationID from local storage
  const organizationID = localStorage.getItem('OrganizationID');

  const [userData, setUserData] = useState({
    // name: '',
    Email: '',
    // contactNumber: '',
    // dateOfBirth: '',
    UniqueID: '',
    Password: '',
    UserType: "Customer"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
      const payload = {
        ...userData,
        organizationID,
      };
      console.log('Payload being sent:', payload);
    try {
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...userData,
          organizationID,
        }),
      });
      
      if (response.ok) {
        console.log('User data submitted successfully');
        alert('User data submitted successfully');
        handleGoBack();
        // You can add code to reset the form or show a success message here
      } else {
        
        console.error('Error submitting user data');
      }
    } catch (error) {
      console.error('Error submitting user data:', error);
    }
  };

  const handleGoBack = () => {
    props.setActive(6);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: "90%", gap: "15px", marginBottom: '6px' }}>
          <img src={Arrow} style={{ cursor: "pointer" }} onClick={handleGoBack} alt="Back" />
          <h2 style={{ fontWeight: 'bold', fontSize: 30, color: '#001E51', justifyContent: "center", alignItem: "center", width: "40%" }}>Add User</h2>
        </div>

        <form onSubmit={handleSubmit} style={{ width: '50%', maxWidth: '30%', fontWeight: 'bold' }}>
          {/* ... other input fields ... */}
          {/* Non-editable OrganizationID field */}
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1%', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '8px', width: '30%', fontSize: 16 }}>Organization ID</label>
            <input
              type="text"
              name="organizationID"
              value={organizationID}
              readOnly
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999', backgroundColor: '#f4f4f4' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1%', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '8px', width: '30%', fontSize: 16 }}>User Unique Id </label>
            <input
              type="text"
              name="UniqueID"
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
          <label style={{ color: '#001E51', marginBottom: '6px', width: '30%',fontSize:16 }}>E-mail id </label>
          <input
            type="email"
            name="Email"
           
            onChange={handleChange}
            required
            style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
          />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
          <label style={{ color: '#001E51', marginBottom: '8px', width: '30%',fontSize:16 }}>Contact Number</label>
          <input
            type="text"
            name="Contact No"
           
            onChange={handleChange}
            required
            style={{ flex: '1', padding: '6px', borderRadius: '4px',border: '1px solid #999999' }}
          />
        </div> */}
        {/* <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
          <label style={{ color: '#001E51', marginBottom: '6px', width: '30%',fontSize:16 }}>Date of Birth</label>
          <input
            type="date"
            name="Plan Name"
         
            onChange={handleChange}
            required
            style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </div> */}
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1%', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '8px', width: '30%', fontSize: 16 }}>Password </label>
            <input
              type="password"
              name="Password"
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', alignSelf: "center", gap: '15px' }}>
            <button type="submit" style={{ backgroundColor: '#001E51', color: 'white', padding: '8px 80px', border: 'none', borderRadius: '6px', cursor: 'pointer', margin: 20 }}>Submit</button>
            <button onClick={handleGoBack} style={{ color: '#001E51', padding: '8px 80px', borderRadius: '6px', cursor: 'pointer', margin: 20 }}>Cancel</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddUsers;
