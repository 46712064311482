import { Box, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import Icon from "@mui/material/Icon";
import { useNavigate } from 'react-router';
import LogoutArrow from '../../images/LoginArrow.png'


export default function Sidebar(props) {
//    const {active, props.setActive, props.handleClick}=props;
  const navigate=useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    window.history.pushState(null, null, '/');
    navigate("/");
  }

  return (
    <Box sx={styles.main}>
        <div style={styles.logo}>
         <img
          style={{ marginLeft: "10px",marginTop:'5px' }}
          src={require("../../images/convene-logo.png")}
          width="30px"
          height="30px"
        />
        
        <Typography sx={[styles.txt, {color:"#FFFFFF", fontWeight:600}]}>ConvenVc</Typography>
        </div>

      <>
      <Box sx={styles.top}>
        <Box
          sx={{
            ...styles.item,
            backgroundColor: props.active === 1 ? "#FFFFFF" : "darkblue",
            color: props.active === 1 ? "darkblue" : "white",
          }}
          onClick={() => {
            props.setActive(1);
            props.handleClick(1);
          }}
        >
        {props.active === 1 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/Dashboardicon.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/Dashboard-icon-white.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>Dashboard</Typography>
        </Box>

        <Box  sx={{
            ...styles.item,
            backgroundColor: props.active === 2 || props.active === 3 ? "#FFFFFF" : "darkblue",
            color: props.active === 2 || props.active === 3 ? "darkblue" : "white",
          }}
          onClick={() => {
            props.setActive(2);
            props.handleClick(2);
          }}>
          {props.active === 2 || props.active === 3 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/meetings-dark.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/meetings.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>Meeting</Typography>
        </Box>
        <Box  sx={{
            ...styles.item,
            backgroundColor: props.active === 6 || props.active === 8 ? "#FFFFFF" : "darkblue",
            color: props.active === 6 || props.active === 8 ? "darkblue" : "white",
          }}
          onClick={() => {
            props.setActive(6);
            props.handleClick(6);
          }}>
          {props.active === 6 || props.active === 8 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/customer-dark.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/customer-icon.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>Users List</Typography>
        </Box>

        {/* end */}

        <Box  sx={{
            ...styles.item,
            backgroundColor: props.active === 7 ? "#FFFFFF" : "darkblue",
            color: props.active === 7 ? "darkblue" : "white",
          }}
          onClick={() => {
            props.setActive(7);
            props.handleClick(7);
          }}>
          {props.active === 7 ? (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/Settings-dark.png")}
          width="20px"
          height="20px"
        />
      ) : (
        <img
          style={{ marginLeft: "10px" }}
          src={require("../../images/settings-icon.png")}
          width="20px"
          height="20px"
        />
      )}
          <Typography sx={styles.txt}>Settings</Typography>
        </Box>
        <Box sx={{marginBottom:"3px"}}>
     
        <div style={{ width: '100px', height: '38px', Width:"20px", borderRadius: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center',marginLeft:"5px",marginBottom:"5px",gap:"10px"}}>
          <img src={LogoutArrow} style={{ width: '20px',height: '20px'}} alt="Logout Arrow" />
        
        <p style={{ color: "white"}} onClick={handleLogout}> Logout</p>
      </div>
        </Box>
      </Box>

      </>
   
    </Box>
  );
}

const styles = {
  main: {
    backgroundColor: "red",
    display: "flex",
    flexDirection: "column",
    backgroundColor:"darkblue",

    justifyContent: "space-between",
    height: " ",
    width: "100%",
    '@media (max-width: 768px)':{
      width:"38px",
    }
  },
  top: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    '@media (max-width: 768px)':{
      marginLeft:"0px",
    }
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    marginTop: 18,
  },
  item: {
    display: "flex",
    flexDirection: "row",
    margin: "auto auto auto 8px",
    alignItems: "center",
    marginY: "10px",
    width: "94%",
    height: "50px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    cursor: "pointer",
    padding:'2px',
    overflow: "hidden",
    transition: "background-color 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "darkblue",
    },
    '@media (max-width: 768px)':{
      margin:'0px',
      padding:'2px',
      marginY:'4px',
    },
  },
  txt: {
    fontFamily: "MontserratRegular",
    fontSize: "16px",
    marginLeft: "8px",
    fontfamily: "Poppins",
    fontSize: "26px",
    
    '@media (max-width: 998px)':{
      fontSize:"14px"
    },
    '@media (max-width: 768px)':{
      color: 'rgba(0, 0, 0, 0)',
    }
    
  },
  [`@media (max-width: 600px)`]: {
    main: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "10px",
      height: "auto",
      minWidth: "initial",

    },
    top: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "10px",
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginTop: "10px",
    },
    item: {
      width: "30%",
      height: "40px",
      margin: "10px",
    },
    txt: {
      marginLeft: "10px",
      fontSize: "14px",
    },
  },
};