import React, { useState } from 'react';
import Settingdark from '../../images/Settings-dark.png';
import editicon from '../../images/editicon.png';
import TextField from '@mui/material/TextField';

const Settings = () => {
  const [activeSetting, setActiveSetting] = useState('account');
  const [showInput, setShowInput] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [userData, setUserData] = useState({
    userId: '',
    userName: '',
    contactNo: '',
    dob: '',
    subscriptionStatus: '',
    email: '',
  });
  
  const handleSettingClick = (setting) => {
    setActiveSetting(setting);
    if (setting==="changePasswords") {
    setShowInput(true);
    }
  };
 
  const handlePasswordClick = () => {
    setShowInput(!showInput);
  };
  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    // Reset the user data to initial values or fetch it again
    // For example, fetchUserData();
  };

  const handleSubmit = async () => {
    const userId = localStorage.getItem('UserID');
    if (!userId) {
      console.error('User ID not found in local storage');
      return;
    }

    const currentPassword = document.getElementById('currentPassword').value;
    const newPassword = document.getElementById('newPassword').value;
    const confirmPassword = document.getElementById('confirmPassword').value;
    if(newPassword !== confirmPassword){
      alert('Password not matched')
    }
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${userId}/change-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ currentPassword, newPassword, confirmPassword }),
      });
  
      if (response.ok) {
        alert('Password changed successfully');
        localStorage.clear();
        window.location.href = '/login';
      } else {
        // Handle error cases
        const errorData = await response.json();
        console.error('Error changing password:', errorData.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  

  return (
    <div style={styles.container}>
      <div style={styles.main}>
        <img src={Settingdark} style={styles.icon} alt="Settings Icon" />
        <h1 style={styles.heading}>Settings</h1>
      </div>
      <div style={styles.setting}>
        <h3
          style={activeSetting === 'account' ? styles.activeSetting : styles.settingText}
          onClick={() => handleSettingClick('account')}
        >
          Account Settings
        </h3>
        <h3
          style={activeSetting === 'general' ? styles.activeSetting : styles.settingText}
          onClick={() => handleSettingClick('general')}
        >
          General Settings
        </h3>
      </div>
      {activeSetting === 'account' && (
        <div>
          <div style={styles.rowContainer}>
            <p style={styles.label}>User id</p>
            {editMode ? (
              <TextField
                id="userId"
                variant="standard"
                sx={styles.inputContainer}
                value={userData.userId}
                onChange={(e) => setUserData({ ...userData, userId: e.target.value })}
              />
            ) : (
              <p style={styles.label}>{userData.userId}</p>
            )}
          </div>
          <div style={styles.rowContainer}>
          <p style={styles.label}>User name</p>
          {editMode ? (
            <TextField
              id="userName"
              variant="standard"
              sx={styles.inputContainer}
              value={userData.userName}
              onChange={(e) => setUserData({ ...userData, userName: e.target.value })}
            />
          ) : (
            <p style={styles.label}>{userData.userName}</p>
          )}
        </div>
        <div style={styles.rowContainer}>
          <p style={styles.label}>Contact No</p>
          {editMode ? (
            <TextField
              id="contactNo"
              variant="standard"
              sx={styles.inputContainer}
              value={userData.contactNo}
              onChange={(e) => setUserData({ ...userData, contactNo: e.target.value })}
            />
          ) : (
            <p style={styles.label}>{userData.contactNo}</p>
          )}
        </div>
        <div style={styles.rowContainer}>
  <p style={styles.label}>Subscription Status</p>
  {editMode ? (
    <TextField
      id="subscriptionStatus"
      variant="standard"
      sx={styles.inputContainer}
      value={userData.subscriptionStatus}
      onChange={(e) => setUserData({ ...userData, subscriptionStatus: e.target.value })}
    />
  ) : (
    <p style={styles.label}>{userData.subscriptionStatus}</p>
  )}
</div>

<div style={styles.rowContainer}>
  <p style={styles.label}>E-mail</p>
  {editMode ? (
    <TextField
      id="email"
      variant="standard"
      sx={styles.inputContainer}
      value={userData.email}
      onChange={(e) => setUserData({ ...userData, email: e.target.value })}
    />
  ) : (
    <p style={styles.label}>{userData.email}</p>
  )}
</div>
          <div style={styles.buttonContainer}>
            {!editMode && (
              <img src={editicon} style={styles.icon} alt="" onClick={handleEditClick} />
            )}
            {editMode && (
              <>
                <button type="submit" style={styles.submitButton} onClick={handleSubmit}>
                  Submit
                </button>
                <button type="button" style={styles.cancelButton} onClick={handleCancelClick}>
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {activeSetting === 'general' && (
        <div>
          <div style={styles.general}>
            <h4 onClick={() => handlePasswordClick('changePassword')}
            >
              Change Password
            </h4>
            {showInput && (
          
                <div style={{width:"100%",justifyContent: "center",alignItems: "center",alignSelf: "center",marginLeft:"70%",flexDirection:"column" }}>
               
                  <input
                  type="password"
                  id="currentPassword"
                  style={{marginBottom:'15px', border: '2px solid #001E51',borderRadius:"4px",fontFamily:"GothicA1",color:"#999",fontSize:13}}
                 placeholder='Current Password'
                 required
                 
                />
               
                <div >
                <input
                type="password"
                id="newPassword"
                style={{marginBottom:'15px', border: '2px solid #001E51',borderRadius:"4px",fontFamily:"GothicA1",color:"#999",fontSize:13 }}
               placeholder='New Password'
               required
               
              />
                </div>
                
                <div >
                <input
                type="password"
                id="confirmPassword"
                style={{marginBottom:'15px', border: '2px solid #001E51',borderRadius:"4px",fontFamily:"GothicA1",color:"#999",fontSize:13}}
               placeholder='Re-Enter Password'
               required
               
              />
                </div>
                <div style={styles.buttonContainer}>
               
                <button type="submit" style={styles.submitButton} onClick={handleSubmit}>
                  Submit
                </button>
                <button type="submit" style={styles.cancelButton}>
                  Cancel
                </button>
              </div>
              </div>
            )}
            <h4 style={{ marginBottom: 1, marginRight: 70 }}>Privacy</h4>
            <h4 style={{ marginBottom: 1 }}>Delete Account</h4>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
    margin: '2%',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35%',
    gap: 10,
  },
  icon: {
    width: 38,
    height: 38,
    marginRight: 10,
  },
  heading: {
    color: '#001E51',
    fontFamily: 'GothicA1',
    fontSize: 50,
    fontWeight: 700,
  },
  setting: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    gap: 90,
    marginBottom: 20,
  },
  settingText: {
    color: '#001E51',
    fontFamily: 'Gothic A1',
    fontSize: 20,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  activeSetting: {
    color: '#0077B6',
    fontFamily: 'Gothic A1',
    fontSize: 20,
    fontWeight: 'bold',
    borderBottom: '2px solid #001E51',
    paddingBottom: '5px',
    cursor: 'pointer',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    gap: 20,
    fontFamily: 'Gothic A1',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#001E51',
    margin: '20px'
  },
  label: {
    margin: 0,
    color: '#001E51',
    fontSize: 16,
    width: '17%',
    fontFamily: 'Gothic A1',
  },
  inputContainer: {
    width: '40%',
    padding: '8px',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    direction: 'ltr',
    color: 'black',
    fontSize: 16,
    margin: 0,
  },
  general: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    color: '#001E51',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'center',
    marginTop: 15,
    marginLeft: '14%',
  },
  submitButton: {
    color: '#001E51',
    padding: '4px 17px',
    borderRadius: '6px',
    cursor: 'pointer',
    border: '2px solid #001E51',
  },
  cancelButton: {
    color: '#001E51',
    padding: '4px 17px',
    borderColor: '#001E51',
    borderRadius: '6px',
    cursor: 'pointer',
    border: '2px solid #001E51',
  },
};

export default Settings;