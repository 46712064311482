// AddCustomers.js
import React, { useState } from 'react';
import Arrow from '../../images/Arrow.png';

function AddCustomers(props) {
  const [customerData, setCustomerData] = useState({
    CustomerID: '',
    FullName: '',
    Email: '',
    OrganizationID: '',
    ContactNumber: '',
    Address: '',
    UniqueID: '',
    UserType: '',
    Password: '',
    AllowedMeetingParticipants: '',
    UserID: '',
    SubscriptionType: '', 
    SubscriptionStartDate: '', 
    SubscriptionEndDate: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/create-customer-details/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(customerData),
      });
      if (response.ok) {
        console.log('Customer data submitted successfully');
        // You can add code to reset the form or show a success message here
      } else {
        console.error('Error submitting customer data');
      }
    } catch (error) {
      console.error('Error submitting customer data:', error);
    }
  };

  console.log(customerData,"data")
  const handleGoBack = () => {
    props.setActive(6);
    
  };
  return (
    <div className="add-customers-page">
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '90%', gap: '15px', marginBottom: '6px' }}>
          <img src={Arrow} onClick={handleGoBack } alt="Back" />
          <h2 style={{ fontWeight: 'bold', fontSize: 30, color: '#001E51', justifyContent: 'center', alignItems: 'center', width: '40%' }}>New Customers</h2>
        </div>

        <form onSubmit={handleSubmit} style={{ width: '50%', maxWidth: '30%', fontWeight: 'bold' }}>

        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontWeight: 'bold', fontSize: 16 }}> Customer Id </label>
            <input
              type="number"
              name="CustomerID"
              value={customerData.CustomerID}
              onChange={handleChange}
              required
              placeholder='8'
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontWeight: 'bold', fontSize: 16 }}> Organization-  Id </label>
            <input
              type="number"
              name="OrganizationID"
              value={customerData.OrganizationID}
              onChange={handleChange}
              required
              placeholder='127593443'
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontWeight: 'bold', fontSize: 16 }}> Full Name </label>
            <input
              type="text"
              name="FullName"
              value={customerData.FullName}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}> Email </label>
            <input
              type="email"
              name="Email"
              value={customerData.Email}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}> Contact Number </label>
            <input
              type="text"
              name="ContactNumber"
              value={customerData.ContactNumber}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}> Address </label>
            <input
              type="text"
              name="Address"
              value={customerData.Address}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}> Unique ID </label>
            <input
              type="text"
              name="UniqueID"
              value={customerData.UniqueID}
              onChange={handleChange}
              required
              placeholder='uniquetxt210'
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}> GSTIN</label>
            <input
              type="text"
              name="GST"
              value={customerData.GST}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}> User Type </label>
            <input
              type="text"
              name="UserType"
              placeholder='Customer'
              value={customerData.UserType}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}> Default Password</label>
            <input
              type="text"
              name="Password"
              value={customerData.Password}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
          <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontWeight: 'bold', fontSize: 16 }}> Allowed Meeting Participants </label>
          <input
            type="number"
            name="AllowedMeetingParticipants"
            value={customerData.AllowedMeetingParticipants}
            onChange={handleChange}
            required
            style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
          />
        </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '8px', alignItems: 'center' }}>
            <label style={{ color: '#001E51', marginBottom: '6px', width: '30%', fontSize: 16 }}>UserID</label>
            <input
              type="number"
              name="UserID"
              value={customerData.UserID}
              onChange={handleChange}
              required
              style={{ flex: '1', padding: '6px', borderRadius: '4px', border: '1px solid #999999' }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
            <button type="submit" style={{ backgroundColor: '#001E51', color: 'white', padding: '8px 80px', border: 'none', borderRadius: '6px', cursor: 'pointer', margin:"20"}}>Submit</button>
            <button onClick={handleGoBack} style={{ color: '#001E51', padding: '8px 80px',  borderRadius: '6px', cursor: 'pointer', margin:20 }}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCustomers;