import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Sidebar from "./Sidebar";
import LandingPage from "../pages/Owner/OwnerLanding";
import MeetingsPage from "../pages/Admin/Meetings";
import AddMeetings from "../components/Owner/AddMeetings";
import CustomersList from "./Owner/CustomersList";
import DemoPage from '../pages/Owner/ShowDemo';
import Seetings from "./Admin/Settings";
import OwnerMeetings from "./Owner/OwnerMeetings";
import AddCustomers from "./Owner/AddCustomers";
import CustomerDetailView from "./Owner/CustomerDetailView";
import MeetingUsageLogs from "./Owner/MeetingUsageLogs";
import Subscriptions from "./Owner/Subscriptions";
import AddSubscriptions from "../components/Owner/AddSubscriptions";

export default function Dashboard(props) {
    const [active, setActive] = useState(1);
    const [customerId ,setCustomerId] = useState("")
    useEffect(()=>{
        if(props.setActive){
        setActive(props.setActive)
        }
    },[props])
    console.log("props",props)
    const handleClick = (key) => {
      setActive(key);
    };

let content = null;
  if (active === 1) {
    content = <LandingPage />
  } else if (active=== 2 ) {
    content = <OwnerMeetings setActive={setActive}/>;
  }
  else if (active === 4) {
    content =<LandingPage/>;
  }
  else if (active=== 5 ) {
    content = <AddMeetings setActive={setActive}/>;
  } 
  else if (active=== 6 ) {
    content = <CustomersList setCustomerId={setCustomerId} setActive={setActive}/>;
  } 
  else if (active=== 8 ) {
    content = <AddCustomers setActive={setActive}/>;
  } 
  else if (active=== 3 ) {
    content = <DemoPage />;
  } 
  else if (active=== 7 ) {
    content = <Seetings />;
  } 
  else if (active=== 9 ) {
    content = <CustomerDetailView customerId={customerId} setActive={setActive}/>;
  } 
  else if (active=== 10 ) {
    content = <MeetingUsageLogs customerId={customerId} setActive={setActive}/>;
  } 
  else if (active=== 11 ) {
    content = <Subscriptions setActive={setActive}/>;
  } 
  else if (active=== 12 ) {
    content = <AddSubscriptions setActive={setActive}/>;
  } 
  else {
    content = <LandingPage/>
  }
  return (
    <>
      <Box sx={styles.mainContainer}>
        {/* ..............navbar */}
        <Box>
        </Box>

        <Box sx={styles.bottomContainer}>
          <Box sx={styles.sidebar}>
            <Sidebar active={active} setActive={setActive} handleClick={handleClick} />
          </Box>
          <Box sx={styles.centerPart}>
          {content}
          </Box>
        </Box>
      </Box>
    </>
  );
}

const styles = {
  mainContainer: {
    width: "100%",
    overflowY: "hidden",
    height: "100%"
    // display:"flex",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "row",

    '@media (max-width: 768px)':{
      flexWrap:"wrap",
    }
  },
  sidebar: {
    width: "15%",
    backgroundColor:"darkblue",
    height:"100vh",
    '@media (max-width: 768px)':{
      height:'auto',
    }

  },
  centerPart: {
    width: "85%",
    overflowX:'hidden',

  },
};