import React, { useState } from "react";
import "../styles/login.css";
import loginImage from "../images/LoginCover.png";
import "@fontsource/gothic-a1";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false); // Track login failure
  const [uniqueID, setUniqueID] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/authenticate/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Email: email,
          Password: password,
          UniqueID: uniqueID,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();

        // console.log(data,'data')
        
        // Store user information in local storage
        localStorage.setItem("authenticated", true);
        localStorage.setItem("UserID", data.UserID);
        localStorage.setItem("userEmail", data.Email);
        localStorage.setItem("UniqueID", data.UniqueID);
        localStorage.setItem("OrganizationID", data.OrganizationID);
        localStorage.setItem("FullName", data.FullName);
  
        setLoginFailed(false);
        props.onAuthentication(true);
        // Redirect based on UserType
        if (data.UserType === "Owner") {
          navigate("/owner-dashboard");
        } else {
          navigate("/admin-dashboard");
        }
      } else {
        setLoginFailed(true);
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  return (
    <>
      <div className="Login-body">
        <img src={loginImage} className="Login-image" alt="logo" />
        <div className="form-section">
          <div className="input-container">
            <h3>User Login</h3>
            <input
              type="text"
              placeholder="Unique ID"
              className="rounded-input"
              value={uniqueID}
              required
              onChange={(e) => setUniqueID(e.target.value)}
            />
            <input
              type="email"
              placeholder="E-mail"
              className="rounded-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              className="rounded-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {/* <div className="Login-Forget-Password">
            <h6>Forget Password?</h6>
          </div> */}
          <button className="login-button" onClick={handleLogin}>
            Login
          </button>
          {loginFailed && <p>Invalid credentials. Please try again.</p>}
          <div className="Login-know-more">
            <h3>Are you new to our website and want to know more about us?</h3>
            {/* <h5>Someone from our team will contact you soon!</h5> */}
            <a href="https://convenevc.com/" className="login-know-button">
              Know more
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
