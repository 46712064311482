import React, { useState, useEffect, useRef } from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';

const JitsiSdk = ({ isUser, eventData, displayName, userEmail, meetingName }) => {

  // const userName = displayName
  // const userEmail = userEmail
  const userId = localStorage.getItem('id');

  const [isJitsiVisible, setIsJitsiVisible] = useState(true);

//  console.log(props,'pros')
  // console.log(meetingName,'nameeeeee')
  const domain = 'advance.convenevc.com'; // Replace with your actual Jitsi Meet server domain
  const roomName = meetingName 
  
  const [jwtToken, setJwtToken] = useState('');
  const [isMeetingActive, setIsMeetingActive] = useState(true);
  const jitsiMeetRef = useRef(null);

  const user_name = displayName
  const user_email = userEmail

  useEffect(() => {
    // Fetch the JWT token from the server
    fetch(`https://token.convenevc.com/generate-jwt?room_name=${roomName}&user_name=${user_name}&user_email=${user_email}`)
      .then((response) => response.json())
      .then((data) => {
        setJwtToken(data.token);
        // console.log(data.token, "tokennnnn");
      })
      .catch((error) => {
        console.error('Error fetching JWT token:', error);
      });
  }, []);

  // Function to handle the leave action when the button is clicked

  // Wait until jwtToken is available before rendering JitsiMeeting component
  if (!jwtToken) {
    return <div>Loading...</div>;
  }


  const userInfo = {
    displayName: 'DummyUser',
    useEmail: 'email@email.com'
  };

  const handleApi = (JitsiMeetAPI) => {
    // Here you can attach custom event listeners to the Jitsi Meet API
    // You can also store it locally to execute commands
    // console.log('Jitsi API initialized:', JitsiMeetAPI);
  };
  const interfaceConfigOverwrite = {
    // Your interface config overwrites here
    DEFAULT_BACKGROUND: '#999999',
   
  };
  const config = {
   

  }
  const configOverwrite = {

  };


  return (
    <>

          <JitsiMeeting
            domain={domain}
            roomName={roomName}
            jwt={jwtToken}
            // eventId={eventId}
            configOverwrite={configOverwrite}
            interfaceConfigOverwrite={interfaceConfigOverwrite}
            // prejoinConfigOverwrite={prejoinConfigOverwrite}
            config={config}
            userInfo={userInfo}
            onAPILoad={handleApi}
            onApiReady={(externalApi) => {
              // Here you can attach custom event listeners to the Jitsi Meet External API
              // You can also store it locally to execute commands
            }}
            onIframeLoad={(iframe) => {
              // Store the iframe reference in the ref for later use
              jitsiMeetRef.current = iframe;

              // Add custom event listeners or interactions with the iframe here
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = '100%';
              iframeRef.style.width = '100%';
            }}
          />
          {/* Render the Leave Meeting button */}
         
        </>
  );
};

export default JitsiSdk;
