import React, { useEffect, useState } from 'react';
import userdark from '../../images/customer-dark.png';

const UsersList = (props) => {
  const [userList, setUserList] = useState([]);
  const organizationID = localStorage.getItem('OrganizationID');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/organization/${organizationID}`);
        const data = await response.json();
        setUserList(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (organizationID) {
      fetchUsers();
    }
  }, [organizationID]);

  return (
    <div style={styles.container}>
      <div style={styles.main}>
        <img src={userdark} style={styles.icon} alt="Demo Icon" />
        <h1 style={styles.heading}>Users</h1>
      </div>
      <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between', alignSelf: 'center', flexDirection: 'row', color: ' #0077B6', fontSize: 22 }}>
        <h2 style={{ marginTop: '0%', fontFamily: 'GothicA1', Color: '#0077B6', fontSize: 28 }}>UserList</h2>
        <button onClick={() => { props.setActive(8) }} style={styles.addButton}> + Add User</button>
      </div>
      <table style={styles.tableUSer}>
        <thead style={{ padding: '7px', width: '20px' }}>
          <tr style={{ fontSize: '14px' }}>
            {/* <th>User ID</th> */}
            <th>Unique ID</th>
            <th>Email</th>
            <th>Organization ID</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '12px' }}>
          {userList?.map((user) => (
            <tr key={user.UserID}>
              
              <td>{user.UniqueID}</td>
              <td>{user.Email}</td>
              <td>{user.OrganizationID}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
    margin: '2%',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35%',
    gap: 10,
  },
  icon: {
    width: 38,
    height: 38,
    marginRight: 10,
  },
  heading: {
    color: '#001E51',
    fontFamily: 'GothicA1',
    fontSize: 50,
    fontWeight: 700,
  },
  addButton: {
    width: '120px',
    height: '40px',
    backgroundColor: '#001E51',
    color: '#FFF',
    borderRadius: '6px',
    cursor: 'pointer',
    border: 'none',
    fontFamily:"GothicA1",
    
  },
  tableUSer: {
    width: '90%',
    marginTop: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf:"center"
  },
};

export default UsersList;