// SubscriptionsPage.js
import React, { useState, useEffect } from 'react';
import '../../styles/meetings.css'; // External CSS file
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SubscriptionsIcon from '../../images/subscriptions-dark.png';

const SubscriptionsPage = (props) => {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [editingSubscriptionId, setEditingSubscriptionId] = useState(null);
  const [editedSubscription, setEditedSubscription] = useState({
    SubscriptionPlanName: '',
    AllowedMeetingParticipants: '',
    SubscriptionAmount: '',
  });

  useEffect(() => {
    // Fetch data from the API when the component mounts
    fetch(`${process.env.REACT_APP_API_URL}/api/subscriptions/`)
      .then((response) => response.json())
      .then((data) => setSubscriptions(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, []); // Empty dependency array to run the effect only once when the component mounts

  const handleViewDetails = (subscriptionId) => {
    // Implement your logic to view details of a specific subscription
    props.setSubscriptionId(subscriptionId);
    props.setActive(11);
    console.log('View Details of Subscription ID:', subscriptionId);
  };

  const handleSave = (subscriptionId) => {
    // Implement your logic to save the changes
    const updatedSubscriptions = subscriptions?.map((subscription) => {
      if (subscription.SubscriptionID === subscriptionId) {
        // Update the edited subscription
        return {
          ...subscription,
          SubscriptionPlanName: editedSubscription.SubscriptionPlanName,
          AllowedMeetingParticipants: editedSubscription.AllowedMeetingParticipants,
          SubscriptionAmount: editedSubscription.SubscriptionAmount,
        };
      }
      return subscription;
    });

    setSubscriptions(updatedSubscriptions);

    // Make the API call to update the subscription in the database
    fetch(`${process.env.REACT_APP_API_URL}/api/subscriptions/${subscriptionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        updatedSubscriptions.find((subscription) => subscription.SubscriptionID === subscriptionId)
      ),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to update subscription in the database');
        }
      })
      .catch((error) => console.error('Error updating subscription:', error));
    setEditingSubscriptionId(null);
  };

  const handleDelete = (subscriptionId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this subscription?');
    if (isConfirmed) {
      setSubscriptions(subscriptions.filter((subscription) => subscription.SubscriptionID !== subscriptionId));

      fetch(`${process.env.REACT_APP_API_URL}/api/subscriptions/${subscriptionId}`, {
        method: 'DELETE',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to delete subscription from the database');
          }
        })
        .catch((error) => console.error('Error deleting subscription:', error));
    }
  };

  const handleInputChange = (field, value) => {
    // Update the state for the edited subscription
    setEditedSubscription((prevEditedSubscription) => ({
      ...prevEditedSubscription,
      [field]: value,
    }));
  };

  return (
    <div className="meetings-page">
      <header>
        <div className='meetings-header-title'>
          <img src={SubscriptionsIcon} alt='' />
          <h1 className='title'>Subscriptions</h1>
        </div>
        <button onClick={() => { props.setActive(12) }} className="new-meeting-btn">+ Add Subscription</button>
      </header>
      <div>
        <h3>Subscriptions List</h3>
        <table>
          <thead>
            <tr>
              <th>Subscription ID</th>
              <th>Name</th>
              <th>Allowed Meeting Participants</th>
              <th>Subscription Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {subscriptions?.map((subscription) => (
              <tr key={subscription.SubscriptionID}>
                <td>{subscription.SubscriptionID}</td>
                <td>
                  {editingSubscriptionId === subscription.SubscriptionID ? (
                    <input
                      type="text"
                      value={editedSubscription.SubscriptionPlanName}
                      onChange={(e) => handleInputChange('Name', e.target.value)}
                    />
                  ) : (
                    subscription.SubscriptionPlanName
                  )}
                </td>
                <td>
                  {editingSubscriptionId === subscription.SubscriptionID ? (
                    <input
                      type="text"
                      value={editedSubscription.AllowedMeetingParticipants}
                      onChange={(e) => handleInputChange('AllowedMeetingParticipants', e.target.value)}
                    />
                  ) : (
                    subscription.AllowedMeetingParticipants
                  )}
                </td>
                <td>
                  {editingSubscriptionId === subscription.SubscriptionID ? (
                    <input
                      type="text"
                      value={editedSubscription.SubscriptionAmount}
                      onChange={(e) => handleInputChange('SubscriptionAmount', e.target.value)}
                    />
                  ) : (
                    subscription.SubscriptionAmount
                  )}
                </td>
                <td>
                  {editingSubscriptionId === subscription.SubscriptionID ? (
                    <>
                      <button onClick={() => handleSave(subscription.SubscriptionID)}>Save</button>
                    </>
                  ) : (
                    <>
                      {/* <Link onClick={() => handleViewDetails(subscription.SubscriptionID)}>View Details</Link>
                      <button onClick={() => handleDelete(subscription.SubscriptionID)}>Delete</button> */}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SubscriptionsPage;
